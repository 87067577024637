<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-aim-detail-wrap"
  >
    <div v-if="hasPermission">
      <div class="w-detail-main">
        <div class="w-crumbs-href">
          <router-link
            tag="span"
            :to="{ path: '/' }"
          >工作台</router-link>
          <i class="el-icon-arrow-right"></i>
          <router-link
            tag="span"
            :to="{ path: '/work' }"
          >招商规划管理</router-link>
          <i class="el-icon-arrow-right"></i>
          <router-link
            tag="span"
            :to="{ path: '/investmentAimMange?to=back' }"
          >招商目标管理</router-link>
          <i class="el-icon-arrow-right"></i>
          <span class="w-white">招商目标详情</span>
        </div>
      </div>
      <div class="w-detail-screen">
        <div class="w-screen-title">
          <h3 class="w-title-gradients">铺位基础信息</h3>
          <span
            @click="toBerthDetails()"
            class="w-berth-edit"
          >铺位管理</span>
        </div>
        <div class="w-is-disabled">
          <div class="w-input-item w-input-sty">
            <span class="w-type-name">项目名称：</span>
            <input
              class="el-input__inner"
              type="text"
              disabled
              v-model="berthInfo.projectName"
              placeholder="请输入品牌关键字"
            />
          </div>
          <div class="w-input-item w-input-sty">
            <span class="w-type-name">铺位类型：</span>
            <input
              class="el-input__inner"
              type="text"
              disabled
              v-model="berthType"
              placeholder="铺位类型"
            />
          </div>
          <div class="w-input-item w-input-sty">
            <span class="w-type-name">铺位号：</span>
            <input
              class="el-input__inner"
              type="text"
              disabled
              v-model="berthInfo.berthNo"
              placeholder="铺位号"
            />
          </div>
          <div class="w-input-item w-input-sty">
            <span class="w-type-name">楼宇楼层：</span>
            <input
              class="el-input__inner"
              type="text"
              disabled
              v-model="floor"
              placeholder="请输入品牌关键字"
            />
          </div>
        </div>
        <div class="w-screen-title">
          <h3 class="w-title-gradients">铺位招商目标</h3>
          <span
            v-if="inApprovalStatus"
            @click="toApprove()"
            class="w-berth-edit"
          >审批进度</span>
        </div>
        <div class="w-is-disabled">
          <div class="w-input-item w-input-sty">
            <span
              class="w-type-name"
              style="width: 100px;"
            >铺位规划业态：</span>
            <el-select
              :disabled="!canClick"
              popper-class="w-block-select-down"
              v-model="formatStr"
              @change="changeFormat"
              placeholder="请选择"
            >
              <el-option
                v-for="item in formatList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="w-input-item w-input-sty">
            <span class="w-type-name">是否主力店：</span>
            <!-- <el-switch
              @change="swichStore"
              :disabled="!canClick"
              v-model="isStore"
              active-color="#13ce66"
              inactive-color="#666666"
            >
            </el-switch> -->
            <el-select
              :disabled="!canClick"
              @change="swichStore"
              v-model="isStore"
              popper-class="w-block-select-down"
              placeholder="请选择"
            >
              <el-option
                v-for="item in storeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="w-table-rent-data">
          <div class="w-table-rent-content">
            <div class="w-table-content-head">
              <span class="w-text">租决信息</span>
              <span
                v-if="canClick"
                class="w-icon"
                @click="rentClickFunc('add')"
              > <i class="el-icon-circle-plus-outline"></i> 添加</span>
            </div>
            <div class="w-table-content-head2">
              <span>序号</span>
              <span>开始年月</span>
              <span>结束年月</span>
              <span>计租面积类型</span>
              <span>计租面积</span>
              <span>租金类型</span>
              <span>标准租金</span>
              <span>保底租金</span>
              <span>审批状态</span>
              <span>操作</span>
            </div>
            <div
              class="w-table-item"
              v-for="(item, index) in berthInfo.list"
              :key="index"
            >
              <span>{{index+1}}</span>
              <span>{{item.startTime}}</span>
              <span>{{item.endTime}}</span>
              <span>{{item.areaType?(item.areaType===1?'建筑面积':'使用面积'):'-'}}</span>
              <span>{{item.areaType===1?berthInfo.buildingArea:berthInfo.useArea}}</span>
              <span>{{item.priceType?(item.priceType===1?'固定租金':'提成租金'):'-'}}</span>
              <span>{{item.rentPrice?item.rentPrice:'-'}}{{item.priceType?(item.priceType===1?' (元/月/㎡)':'%'):''}}</span>
              <span>{{item.bottomPrice?item.bottomPrice:'-'}}{{item.priceType?(item.priceType===1?' (元/月/㎡)':'(元/月/㎡)'):''}}</span>
              <span>{{isChain && item.approvalStatus?item.approvalStatus:'-'}}</span>
              <div
                class="w-handle-btn"
                v-if="canClick"
              >
                <span @click="rentClickFunc('edit', item, index)">编辑</span>
                <span @click="delDialog(index)">删除</span>
              </div>
            </div>
          </div>
          <div
            class="details-no-data"
            v-if="berthInfo.list && berthInfo.list.length === 0"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>暂无数据</p>
          </div>
        </div>
        <div
          v-if="canClick"
          class="w-query-btn"
          @click="saveBerth()"
        >
          <span>保存</span>
        </div>
      </div>
      <el-dialog
        title="铺位招商目标"
        :visible.sync="dialogVisible"
        width="60%"
        custom-class="dialog-custom"
      >
        <div class="w-rent-top">
          <div class="w-dialog-input">
            <span class="w-type-name">租赁时间：</span>
            <el-date-picker
              v-model="time"
              type="monthrange"
              clearable
              @change="changeTime"
              value-format='yyyy-MM'
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div class="w-dialog-input">
            <span class="w-type-name">计算面积类型：</span>
            <el-select
              v-model="areaId"
              @change="changeAreaType"
              popper-class="floorSelectPoper"
              placeholder="请选择"
            >
              <el-option
                v-for="item in areaType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="w-rent-bottom">
          <div class="w-dialog-input">
            <span class="w-type-name">租金类型：</span>
            <el-select
              v-model="priceType"
              @change="changePriceType"
              popper-class="floorSelectPoper"
              placeholder="请选择"
            >
              <el-option
                v-for="item in rentType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="w-dialog-input">
            <span class="w-type-name">标准租金：</span>
            <input
              class="el-input__inner"
              type="text"
              v-model="rentPrice"
              :placeholder="priceType === 1 ? '请输入标准租金' : '请输入租金比例'"
            />
            <i v-if="priceType === 1"> 元/月/㎡</i>
            <i v-if="priceType === 2"> %</i>
          </div>
          <div class="w-dialog-input">
            <span class="w-type-name">保底租金：</span>
            <input
              class="el-input__inner"
              type="text"
              v-model="bottomPrice"
              placeholder="请输入保底租金"
            />
            <i> 元/月/㎡</i>
          </div>
        </div>

        <div class="w-dialog-btn">
          <span @click="dialogVisible = false">取消</span>
          <span
            @click="berthClickSave()"
            class="w-sure"
          >确定</span>
        </div>
      </el-dialog>
      <!-- 审批进度 -->
      <el-dialog
        width="60%"
        title="审批内容"
        :visible.sync="approveVisable"
      >
        <el-table
          :data="approvalContent"
          :border="true"
        >
          <el-table-column
            property="approvalFormatStr"
            label="铺位规划业态"
            width="100"
            align="center"
          >
            <el-table-column
              property="number"
              label="序号"
              width="120"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="approvalFormatStr ? approvalFormatStr : '--'"
            width="350"
            align="center"
          >
            <el-table-column
              property="changeType"
              label="变更类型"
              align="center"
            ></el-table-column>
            <el-table-column
              property="leaseTime"
              width="190"
              label="租赁时间"
              align="center"
            ></el-table-column>
            <el-table-column
              property="groupAreaType"
              label="计租面积类型"
              align="center"
            ></el-table-column>
          </el-table-column>
          <el-table-column
            property="IsStore"
            label="是否主力店"
            align="center"
          >
            <el-table-column
              property="groupArea"
              label="计租面积"
              align="center"
            ></el-table-column>
          </el-table-column>
          <el-table-column
            :label="approvalIsStore === 0 ? '普通店': approvalIsStore === 1 ? '主力店' : '次主力店'"
            align="center"
          >
            <el-table-column
              property="rentType"
              label="租金类型"
              align="center"
            ></el-table-column>
            <el-table-column
              property="standardRent"
              label="标准租金"
              align="center"
            ></el-table-column>
            <el-table-column
              property="guaranteedRent"
              label="保底租金"
              align="center"
            ></el-table-column>
          </el-table-column>
        </el-table>
        <span class="approveTitle">审批进度</span>
        <el-table
          :data="approvalProgress"
          :border="true"
        >
          <el-table-column
            property="number"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            property="point"
            label="节点"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            property="approver"
            label="审批人"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            property="action"
            label="动作"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            property="operationTime"
            label="操作时间"
            width="220"
            align="center"
          ></el-table-column>
          <el-table-column
            property="opinion"
            label="意见"
            align="center"
          ></el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
    <!-- 截图 -->
    <div
      class="pictrue"
      ref="pictrueShoot"
      id="pictrueShoot"
    >
      <p class="shootTitle">变更内容</p>
      <table
        class="tableBorder"
        width="100%"
        cellpadding="0"
      >
        <tr>
          <td>项目名称</td>
          <td>{{berthInfo.projectName?berthInfo.projectName:'--'}}</td>
          <td>楼宇楼层</td>
          <td>{{floor?floor:'--'}}</td>
          <td>铺位号</td>
          <td>{{berthInfo.berthNo?berthInfo.berthNo:'--'}}</td>
          <td>铺位类型</td>
          <td>{{berthType?berthType:'--'}}</td>
        </tr>
        <tr class="lastTr">
          <td>铺位规划业态</td>
          <td colspan="3">{{formatStr || '--'}}</td>
          <td>是否主力店</td>
          <td colspan="3">
            {{isStore === 0 ? '普通店' : (isStore === 1 ? '主力店' : '次主力店')}}
          </td>
        </tr>
      </table>
      <table
        class="tableBorder"
        width="100%"
        cellpadding="0"
      >
        <tr>
          <td>序号</td>
          <td>变更类型</td>
          <td>租赁时间</td>
          <td>计租面积类型</td>
          <td>计租面积</td>
          <td>租金类型</td>
          <td>标准租金</td>
          <td>保底租金</td>
        </tr>
        <tr
          v-for="(item, index) in changeContent"
          :key="index"
        >
          <td>{{index + 1}}</td>
          <td>{{item.changeType}}</td>
          <td>{{item.leasingTime}}</td>
          <td>{{item.groupAreaType}}</td>
          <td>{{item.leaseArea}}</td>
          <td>{{item.rentType}}</td>
          <td>{{item.standardRent}}</td>
          <td>{{item.banderRent}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import html2canvas from 'html2canvas'
import { b64toBlob } from '@/utils/base64.js'
import { mapGetters } from 'vuex'
import { getCookie } from '@/utils'
export default {
  data () {
    return {
      loading: true,
      projectName: '',
      berthType: '',
      inApprovalStatus: '', // 当前审批状态，true为审批中，false为不在审批中
      canClick: '', // true是可点击，false不可点击
      berthNo: '',
      floor: '',
      isStore: null,
      dialogVisible: false,
      berthId: '',
      id: '',
      time: '',
      berthInfo: {},
      formatStr: '',
      formatList: [],
      priceType: 1,
      rentType: [{
        value: 1,
        label: '固定租金'
      }, {
        value: 2,
        label: '提成租金'
      }],
      rentPrice: '',
      bottomPrice: '',
      areaType: [{
        value: 1,
        label: '建筑面积'
      }, {
        value: 2,
        label: '使用面积'
      }],
      areaId: 1,
      startTime: '',
      endTime: '',
      type: '',
      targetId: '',
      targetIdtemporary: '',
      userAuthorities: [],
      hasPermission: true, // 是否有权限，show也是这个
      hasPermissionEdit: true,
      from: '',
      modulesList: [],
      // 审批内容
      approvalContent: [],
      approvalIsStore: '',
      approvalFormatStr: '',
      // 审批进度
      approvalProgress: [],
      approveVisable: false,
      // 处理数据临时用
      temp: {
        formatStr: '', // 铺位规划业态
        isStore: null, // 是否主力店
        berthInfoList: [], // 存放原始租决信息
        delList: [], // 删除的租决信息
        updataList: [], // 编辑的租决信息
        addList: [] // 新增的租决信息
      },
      // 下标
      subscript: '',
      isChain: '',
      htmlUrl: '',
      // 上传审批单
      changeContent: [],
      delIndex: '',
      imgUrl: '',
      canSubmitChain: '',
      storeOptions: [
        { value: 1, label: '主力店' },
        { value: 0, label: '普通店' },
        { value: 2, label: '次主力店' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.id = this.$route.query.id
    this.berthId = this.$route.query.berthId
    this.targetIdtemporary = this.$route.query.targetId
    this.projectId = this.$route.query.projectId
    this.from = this.$route.query.type
    if (this.$route.query.berthType === '1') {
      this.berthType = '铺位'
    } else if (this.$route.query.berthType === '2') {
      this.berthType = '广告位'
    } else if (this.$route.query.berthType === '3') {
      this.berthType = '场地'
    } else {
      this.berthType = '写字楼'
    }
    this.getBerthDetail()
    this.getModularListFunc()
    this.getGDWS()
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 210
    })
    if (this.userAuthorities.length) {
      this.hasPermission = Boolean(this.userAuthorities[0].func)
      this.hasPermissionEdit = this.userAuthorities[0].func.indexOf('edit') !== -1
    } else {
      this.hasPermission = false
      this.loading = false
    }
    window.addEventListener('popstate', (e) => {
      console.log(e)
      var state = {
        to: 'back',
        url: '#'
      }
      window.history.pushState(state, 'title', '#')
    }, false)
  },
  methods: {
    // 判断是否启用审批链
    getBerthChainIsNotRnabled () {
      this.axios.post(api.berthChainIsNotRnabled)
        .then((res) => {
          if (res.data.code === 0) {
            if (res.data.data === 1) {
              this.isChain = true
              // 获取业态、是否主力店
              this.axios.post(api.getTargetResult, { berthId: this.berthId }).then((res) => {
                if (res.data.code === 0) {
                  this.approvalFormatStr = res.data.data.updateindustry
                  this.approvalIsStore = res.data.data.updatemainStore
                }
              })
              // 获取进度
              const params = {
                projectId: this.projectId,
                taskId: this.id
              }
              this.axios.post(api.queryTargetFlowChartData, params).then((res) => {
                if (res.data.code === 0) {
                  if (res.data.data.data !== null) {
                    const Arr = res.data.data.data.tableList
                    this.approvalProgress = Arr.map((item, index) => {
                      return {
                        number: index + 1,
                        point: item.nodeName,
                        approver: item.examineUserName,
                        action: item.actionName,
                        operationTime: item.examineTime,
                        opinion: item.examineRemark
                      }
                    })
                  }
                }
              })
              // 获取审批内容
              this.axios.post(api.getInformation, { targetId: this.id }).then((res) => {
                if (res.data.code === 0) {
                  this.approvalContent = res.data.data.map((item, index) => {
                    return {
                      number: index + 1,
                      changeType: item.type,
                      leaseTime: item.leaseTime,
                      groupAreaType: item.typeOfLeasedArea,
                      groupArea: item.meterRentArea ? item.meterRentArea : item.typeOfLeasedArea === '建筑面积' ? this.berthInfo.buildingArea : this.berthInfo.useArea,
                      rentType: item.rentTypes,
                      standardRent: item.rentTypes === '固定租金' ? (item.standardRent + '(元/月/㎡)') : (item.standardRent + '%'),
                      guaranteedRent: item.guaranteedTheRent + '(元/月/㎡)'
                    }
                  })
                }
              })
            } else {
              this.isChain = false
            }
          }
        })
    },
    toBerthDetails () {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/assets/unit/details?menuCode=11&unitId=' + this.berthId + '&tokenval=' + getCookie('wisdom_token'), '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    },
    toApprove () {
      this.approveVisable = true
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 11
          })
        })
    },
    getBerthDetail () {
      this.axios.post(api.getBerthInfo, { berthId: this.berthId })
        .then((res) => {
          this.berthInfo = res.data.data
          this.temp.berthInfoList = res.data.data.list.map(item => {
            return item
          })
          this.isStore = this.berthInfo.mainStore
          this.temp.isStore = this.berthInfo.mainStore
          this.floor = this.$route.query.building + '/' + this.berthInfo.floor
          this.loading = false
          this.getApprovalSheetStatus()
          this.getListIndustry()
          this.getBerthChainIsNotRnabled()
        })
    },
    getCanClick () {
      if (this.from === '1') {
        this.canClick = false
      } else {
        if (this.inApprovalStatus) {
          this.canClick = false
        } else {
          this.canClick = true
        }
      }
      // console.log('canClick', this.canClick)
    },
    getApprovalSheetStatus () {
      this.axios.post(api.approvalSheetStatus, { id: this.id }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data > 0) {
            this.inApprovalStatus = true
          } else {
            this.inApprovalStatus = false
          }
        }
        this.getCanClick()
      })
    },
    getListIndustry () {
      this.axios.post(api.getListIndustry1).then((res) => {
        this.formatList = res.data.data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        this.formatList.unshift({
          label: '请选择',
          value: ''
        })
        if (this.berthInfo.industry) {
          this.formatStr = this.berthInfo.industry
          this.temp.formatStr = this.berthInfo.industry
        }
      })
    },
    getGDWS () {
      // 判断高德是否已经部署审批链。type传“新增”就可以了，可以查到能否“新增”、“修改”、“删除”
      const params = {
        projectId: this.projectId,
        type: '新增'
      }
      this.axios.post(api.gdwsApprovalChainIsNotRnabled, params)
        .then((res) => {
          if (res.data.data === 1) {
            this.canSubmitChain = true
          } else {
            this.canSubmitChain = false
          }
        })
    },
    // 添加、删除、编辑租决信息-页面编辑-不提交数据到接口
    CUDLocal () {
      return new Promise((resolve) => {
        // 1.删除租决信息
        const delArr = this.temp.delList.filter((item) => {
          return item.targetId !== ''
        })
        // console.log('这是要删除的租决信息：', delArr)
        if (delArr.length > 0) {
          for (let i = 0; i < delArr.length; i++) {
            const leasingTime = delArr[i].startTime.replace(/-/g, '/') + '-' + delArr[i].endTime.replace(/-/g, '/')
            this.changeContent.push({
              changeType: '删除',
              leasingTime: leasingTime,
              groupAreaType: delArr[i].areaType === 1 ? '建筑面积' : '使用面积',
              leaseArea: delArr[i].areaType === 1 ? this.berthInfo.buildingArea : this.berthInfo.useArea,
              rentType: delArr[i].priceType === 1 ? '固定租金' : '提成租金',
              standardRent: delArr[i].priceType === 1 ? (delArr[i].rentPrice + '(元/月/㎡)') : (delArr[i].rentPrice + '%'),
              banderRent: delArr[i].bottomPrice + '(元/月/㎡)'
            })
          }
        }
        // 2.编辑租决信息
        const updataArr = this.temp.updataList.filter((item) => {
          return item.targetId !== ''
        })
        // console.log('这是要更改的租决信息：', updataArr)
        if (updataArr.length > 0) {
          for (let i = 0; i < updataArr.length; i++) {
            const leasingTime = updataArr[i].startTime.replace(/-/g, '/') + '-' + updataArr[i].endTime.replace(/-/g, '/')
            this.changeContent.push({
              changeType: '修改',
              leasingTime: leasingTime,
              groupAreaType: updataArr[i].areaType === 1 ? '建筑面积' : '使用面积',
              leaseArea: updataArr[i].areaType === 1 ? this.berthInfo.buildingArea : this.berthInfo.useArea,
              rentType: updataArr[i].priceType === 1 ? '固定租金' : '提成租金',
              standardRent: updataArr[i].priceType === 1 ? (updataArr[i].rentPrice + '(元/月/㎡)') : (updataArr[i].rentPrice + '%'),
              banderRent: updataArr[i].bottomPrice + '(元/月/㎡)'
            })
          }
        }
        // 3.添加租决信息
        const AddArr = this.temp.addList
        // console.log('这是要增的租决信息：', AddArr)
        for (let i = 0; i < AddArr.length; i++) {
          const leasingTime = AddArr[i].startTime.replace(/-/g, '/') + '-' + AddArr[i].endTime.replace(/-/g, '/')
          this.changeContent.push({
            changeType: '新增',
            leasingTime: leasingTime,
            groupAreaType: AddArr[i].areaType === 1 ? '建筑面积' : '使用面积',
            leaseArea: AddArr[i].areaType === 1 ? this.berthInfo.buildingArea : this.berthInfo.useArea,
            rentType: AddArr[i].priceType === 1 ? '固定租金' : '提成租金',
            standardRent: AddArr[i].priceType === 1 ? (AddArr[i].rentPrice + '(元/月/㎡)') : (AddArr[i].rentPrice + '%'),
            banderRent: AddArr[i].bottomPrice + '(元/月/㎡)'
          })
        }
        resolve()
      })
    },
    // 添加、删除、编辑租决信息-提交数据到接口
    CUDsubmit () {
      this.loading = false
      const params = {
        berthId: this.berthId,
        id: this.id,
        industry: this.formatStr,
        mainStore: this.isStore,
        addBerthTargetApprove: [],
        deleteBerthTargetApprove: [],
        updateBerthTargetApprove: [],
        url: this.imgUrl
      }
      params.addBerthTargetApprove = this.temp.addList.map(item => {
        return {
          berthId: this.berthId,
          optionType: 1,
          updateAreaType: item.areaType,
          updateBottomPrice: item.bottomPrice,
          updateEndTime: item.endTime,
          updatePriceType: item.priceType,
          updateRentPrice: item.rentPrice,
          updateStartTime: item.startTime
        }
      })
      const updataArr = this.temp.updataList.filter((item) => {
        return item.targetId !== ''
      })
      params.updateBerthTargetApprove = updataArr.map(item => {
        return {
          berthId: this.id,
          optionType: 2,
          targetId: item.targetId,
          updateAreaType: item.areaType,
          updateBottomPrice: item.bottomPrice,
          updateEndTime: item.endTime,
          updatePriceType: item.priceType,
          updateRentPrice: item.rentPrice,
          updateStartTime: item.startTime
        }
      })
      const delArr = this.temp.delList.filter((item) => {
        return item.targetId !== ''
      })
      params.deleteBerthTargetApprove = delArr.map(item => {
        return {
          berthId: this.id,
          optionType: 3,
          targetId: item.targetId,
          updateAreaType: item.areaType,
          updateBottomPrice: item.bottomPrice,
          updateEndTime: item.endTime,
          updatePriceType: item.priceType,
          updateRentPrice: item.rentPrice,
          updateStartTime: item.startTime
        }
      })
      this.axios.post(api.multipleTasksOneApprovalSheet, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.loading = false
            this.$message({
              type: 'success',
              message: '提交成功，招商目标变更将在审批通过后生效'
            })
            this.getBerthDetail()
            // console.log('res', res)
          }
        })
    },
    // 未启用审批链的提交数据
    CUDnoChain () {
      // 1.铺位规划业态，是否主力店
      if (this.temp.formatStr !== this.formatStr || this.temp.isStore !== this.isStore) {
        this.saveIndustryMainStore()
      }
      // 2.编辑租决信息
      const updataArr = this.temp.updataList.filter((item) => {
        return item.targetId !== ''
      })
      // console.log('这是要更改的租决信息：', updataArr)
      if (updataArr.length > 0) {
        for (let i = 0; i < updataArr.length; i++) {
          const Temp = updataArr[i]
          this.updateRentType(Temp)
        }
      }
      this.temp.updataList = []
      // 3.删除租决信息
      const delArr = this.temp.delList.filter((item) => {
        return item.targetId !== ''
      })
      // console.log('这是要删除的租决信息：', delArr)
      if (delArr.length > 0) {
        for (let i = 0; i < delArr.length; i++) {
          const Temp = delArr[i]
          this.deleteRent(Temp)
        }
      }
      this.temp.delList = []
      // 4.添加租决信息
      const AddArr = this.temp.addList
      // console.log('这是要增的租决信息：', AddArr)
      if (AddArr.length > 0) {
        for (let i = 0; i < AddArr.length; i++) {
          const Temp = AddArr[i]
          this.addRentType(Temp)
        }
      }
      this.temp.addList = []
      setTimeout(() => {
        this.$message({
          type: 'success',
          message: '保存成功'
        })
        this.getBerthDetail()
      }, 1000)
      // console.log('this.temp.updataList', this.temp)
    },
    saveBerth () {
      // 判断数据是否有变化
      if (this.temp.formatStr !== this.formatStr || this.temp.isStore !== this.isStore || this.temp.delList.length > 0 || this.temp.updataList.length > 0 || this.temp.addList.length > 0) {
        // 启用审批链
        this.loading = true
        if (this.isChain && this.canSubmitChain) {
          // console.log('启用审批链')
          this.CUDLocal().then(() => {
            this.saveImg('pictrueShoot')
          })
        } else {
          // console.log('未启用审批链')
          this.CUDnoChain()
        }
      } else {
        // console.log('数据没变')
        this.$router.back()
      }
    },
    changePriceType (val) {
      console.log(val)
    },
    changeAreaType (val) {
      console.log(val)
    },
    changeTime (val) {
      if (val) {
        this.startTime = val[0]
        this.endTime = val[1]
      } else {
        this.time = ''
        this.startTime = ''
        this.endTime = ''
      }
    },
    changeFormat (val) {
      if (!this.inApprovalStatus) {
        this.$message({
          type: 'warning',
          message: '点击保存后生效'
        })
      }
      this.formatStr = val
    },
    // 比较时间是否重叠
    isDuringDate (a, b, c, d) {
      var startTime = Number(a.replace('-', ''))
      var endTime = Number(b.replace('-', ''))
      var beginDate = Number(c.replace('-', ''))
      var endDate = Number(d.replace('-', ''))
      if (endTime < beginDate || startTime > endDate) {
        return true
      }
      return false
    },
    berthClickSave () {
      if (!this.time) {
        this.$message({
          type: 'warning',
          message: '租赁时间不能为空！'
        })
        return false
      }
      // 判断同一时间内有无重复租金类型
      var isDuringTime = []
      var duringList = []
      var isDuring = []
      if (this.type === 'edit') {
        for (let i = 0; i < this.berthInfo.list.length; i++) {
          if (i !== this.subscript) {
            const variable = this.isDuringDate(this.time[0], this.time[1], this.berthInfo.list[i].startTime, this.berthInfo.list[i].endTime)
            isDuringTime.push(variable)
            if (!variable) {
              duringList.push(this.berthInfo.list[i])
            }
          }
        }
        const Arr = duringList.filter(item => {
          return item.priceType === this.priceType
        })
        isDuring = isDuringTime.every(item => item === true)
        if (!isDuring && Arr.length > 0) {
          this.$message({
            type: 'warning',
            message: '同一时间段内有重复租金类型'
          })
          return false
        }
      } else {
        for (let i = 0; i < this.berthInfo.list.length; i++) {
          const variable = this.isDuringDate(this.time[0], this.time[1], this.berthInfo.list[i].startTime, this.berthInfo.list[i].endTime)
          isDuringTime.push(variable)
          if (!variable) {
            duringList.push(this.berthInfo.list[i])
          }
        }
        const Arr = duringList.filter(item => {
          return item.priceType === this.priceType
        })
        isDuring = isDuringTime.every(item => item === true)
        if (!isDuring && Arr.length > 0) {
          this.$message({
            type: 'warning',
            message: '同一时间段内有重复租金类型'
          })
          return false
        }
      }
      // 判断标准租金是否有值
      if (!this.rentPrice) {
        this.$message({
          type: 'warning',
          message: '标准租金不能为空！'
        })
        return false
      }
      // 判断保底租金是否有值
      if (!this.bottomPrice) {
        this.$message({
          type: 'warning',
          message: '保底租金不能为空！'
        })
        return false
      }
      this.data = {
        areaType: this.areaId,
        berthId: this.berthId,
        bottomPrice: this.bottomPrice,
        startTime: this.time ? this.time[0] : '',
        endTime: this.time ? this.time[1] : '',
        priceType: this.priceType,
        rentPrice: this.rentPrice,
        targetId: ''
      }
      // console.log(this.data)
      if (this.type === 'add') {
        // this.addRentType()
        this.berthInfo.list = this.berthInfo.list.concat(this.data)
        this.temp.addList.push(this.data)
        this.$message({
          type: 'warning',
          message: '点击保存后生效'
        })
        this.dialogVisible = false
      } else {
        this.berthInfo.list[this.subscript].areaType = this.data.areaType
        this.berthInfo.list[this.subscript].berthId = this.data.berthId
        this.berthInfo.list[this.subscript].bottomPrice = this.data.bottomPrice
        this.berthInfo.list[this.subscript].startTime = this.data.startTime
        this.berthInfo.list[this.subscript].endTime = this.data.endTime
        this.berthInfo.list[this.subscript].priceType = this.data.priceType
        this.berthInfo.list[this.subscript].rentPrice = this.data.rentPrice
        this.data.targetId = this.targetId
        this.berthInfo.list[this.subscript].targetId = this.targetId
        this.temp.updataList.push(this.data)
        // console.log('this.temp.updataList', this.temp.updataList)
        // console.log('this.temp.addList', this.temp.addList)
        this.$message({
          type: 'warning',
          message: '点击保存后生效'
        })
        this.dialogVisible = false
      }
    },
    rentClickFunc (type, item, index) {
      if (!this.hasPermissionEdit) {
        this.$message({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      this.type = type
      if (type === 'edit') {
        this.targetId = item.targetId
        this.priceType = item.priceType
        this.rentPrice = item.rentPrice
        this.bottomPrice = item.bottomPrice
        this.areaId = item.areaType
        this.time = [item.startTime, item.endTime]
        this.subscript = index
      } else {
        this.rentPrice = ''
        this.bottomPrice = ''
        this.time = ''
      }
      this.dialogVisible = true
    },
    // 修改‘是否主力店’后提示语
    swichStore () {
      if (!this.approvalSheetStatus) {
        this.$message({
          type: 'warning',
          message: '点击保存后生效'
        })
      }
    },
    // 提交铺位规划业态、是否主力店——未在审批链
    saveIndustryMainStore () {
      const params = {
        berthId: this.berthId,
        industry1: this.formatStr,
        mainStore: this.isStore
      }
      this.axios.post(api.saveIndustryMainStore, params)
        .then((res) => {
          if (res.data.code === 0) {
            console.log('铺位规划业态、主力店信息添加成功')
          }
        })
    },
    // 添加租决信息接口——未在审批链
    addRentType (data) {
      this.axios.post(api.addRentMsg, data)
        .then((res) => {
          if (res.data.code === 0) {
            console.log('添加成功')
          }
        })
    },
    // 编辑租决信息接口——未在审批链
    updateRentType (data) {
      this.axios.post(api.updateRentMsg, data)
        .then((res) => {
          if (res.data.code === 0) {
            console.log('修改成功')
          }
        })
    },
    delDialog (index) {
      this.delIndex = index
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        iconClass: ''
      }).then(() => {
        this.deleteLocalRent()
      }).catch((err) => {
        console.log(err)
      })
    },
    // 删除租决信息——页面本地删除
    deleteLocalRent () {
      // isDelete是1，审批状态是“驳回”，直接删除。否则审批状态是其他，则要审批
      if (this.berthInfo.list[this.delIndex].isDelete === 1) {
        this.deleteRent(this.berthInfo.list[this.delIndex])
        this.berthInfo.list.splice(this.delIndex, 1)
        this.$message({
          type: 'success',
          message: '删除成功'
        })
      } else {
        if (this.berthInfo.list[this.delIndex].targetId) {
          this.temp.delList.push(this.berthInfo.list[this.delIndex])
          const Arr = this.temp.updataList.filter(item => {
            return item.targetId !== this.berthInfo.list[this.delIndex].targetId
          })
          this.temp.updataList = Arr
          // console.log('this.temp.updataList', this.temp.updataList)
          // console.log('Arr', Arr)
        }
        this.berthInfo.list.splice(this.delIndex, 1)
        this.$message({
          type: 'warning',
          message: '点击保存后生效'
        })
      }
    },
    // 删除租决信息接口——未在审批链
    deleteRent (item) {
      this.axios.post(api.deleteRentMsg, { id: item.targetId })
        .then((res) => {
          console.log('删除成功')
        })
    },
    // 截图
    saveImg (val) {
      window.pageYoffset = 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      // 先获取你要转换为img的dom节点
      var node = document.getElementById(val)// 传入的id名称
      // console.log("node", node);
      var width = node.offsetWidth // dom宽
      var height = node.offsetHeight // dom高
      var scale = 1.5 // 放大倍数 这个相当于清晰度 调大一点更清晰一点
      html2canvas(node, {
        width: width,
        heigth: height,
        backgroundColor: '#ffffff', // 背景颜色 为null是透明
        dpi: 96, // 按屏幕像素比增加像素
        // dpi: window.devicePixelRatio * 2, // 按屏幕像素比增加像素
        scale: scale,
        X: 0,
        Y: 0,
        scrollX: -3, // 如果左边多个白边 设置该偏移-3 或者更多
        scrollY: 0,
        useCORS: true, // 是否使用CORS从服务器加载图像 !!!
        allowTaint: true // 是否允许跨域图像污染画布  !!!
      }).then(canvas => {
        var url = canvas.toDataURL() // 这里上面不设值cors会报错
        const base64 = b64toBlob(url)
        const files = new window.File([base64], '111.png', { type: 'image/png' })
        var forms = new FormData()
        var configs = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
        forms.append('file', files)
        this.axios.post(api.ChainPicture, forms, configs)
          .then((res) => {
            if (res.status === 200) {
              this.imgUrl = res.data.data
              this.CUDsubmit()
            }
          })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.el-input__inner
  font-size 14px
.w-aim-detail-wrap
  margin-top 10px
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .w-detail-screen
    padding 26px 0
    background-color #272930
    .w-screen-title
      display flex
      align-items center
      .w-berth-edit
        font-size 14px
        font-weight 400
        color #FFA134
        margin-left auto
        margin-right 32px
        cursor pointer
    .w-is-disabled
      padding 0 32px
      margin-top 28px
      margin-bottom 28px
      display flex
      .w-input-item
        display flex
        align-items center
        margin-right 56px
        .w-type-name
          display inline-block
          margin-right 4px
          color #fff
          font-size 14px
          font-weight 400
.w-table-rent-data
  .w-table-rent-content
    margin 0 32px
    margin-top 38px
    border-left 1px solid rgba(226, 226, 226, 0.1)
    border-right 1px solid rgba(226, 226, 226, 0.1)
    .w-table-content-head
      display flex
      height 36px
      align-items center
      background-color rgba(255, 255, 255, 0.05)
      .w-text
        font-size 14px
        color #fff
        padding-left 25px
      .w-icon
        color #FFA134
        font-size 14px
        margin-left auto
        padding-right 25px
        cursor pointer
  .w-table-content-head2
    height 47px
    line-height 47px
    border-bottom 1px solid rgba(226, 226, 226, 0.1)
    & span:first-child
      width 5%
    span
      display inline-block
      width 10%
      font-size 13px
      font-weight normal
      color #999999
      padding-left 20px
      box-sizing border-box
  .w-table-item
    height 47px
    line-height 47px
    border-bottom 1px solid rgba(226, 226, 226, 0.1)
    & span:first-child
      width 5%
    &:hover
      background-color #23252b
    >span
      display inline-block
      width 10%
      font-size 13px
      font-weight normal
      color #fff
      padding-left 20px
      box-sizing border-box
      .w-store-status
        display inline-block
        width 10px
        height 10px
        border-radius 50%
        background-color #512D65
        margin-right 5px
    .w-handle-btn
      display inline-block
      padding-left 20px
      span
        color #FFA134
        font-size 14px
        margin-right 20px
        cursor pointer
.w-query-btn
  margin-left 32px
  margin-top 32px
.dialog-custom
  .w-rent-top
    display flex
    .w-dialog-input
      margin 0 15px
      .w-type-name
        font-size 14px
        font-weight 500
        color #333333
      i
        color #999
        font-size 14px
        font-weight 400
  .w-rent-bottom
    display flex
    margin-top 32px
    .w-dialog-input
      margin 0 15px
      .w-type-name
        font-size 14px
        font-weight 500
        color #333333
      i
        color #999
        font-size 14px
        font-weight 400
  .w-dialog-btn
    margin auto
    text-align center
    margin-top 60px
    span
      display inline-block
      width 200px
      height 40px
      background #FFFFFF
      border 1px solid #FFA134
      border-radius 4px
      line-height 40px
      color #FFA134
      font-size 16px
      font-weight 400
      margin 0 20px
      cursor pointer
      &.w-sure
        width 200px
        height 40px
        background #FFA134
        border-radius 4px
        color #ffffff
        font-size 16px
        font-weight 400
/deep/ .el-dialog__body
  padding 0 20px 20px 20px
/deep/ .el-table thead.is-group th
  background #fff
/deep/ .cell
  color #000
  font-weight 400
.approveTitle
  line-height 50px
  height 50px
  font-size 18px
  color #303133
.pictrue
  z-index -1
  position fixed
  padding 10px
  text-align center
  top -1000px
  left -1000px
  width 1470px
  background-color #fff
  .lastTr td
    border-bottom none
.shootTitle
  font-size 24px
  text-align center
  padding-bottom 15px
.tableBorder
  border-top 1px solid #000
  border-left 1px solid #000
  font-size 14px
  td
    height 47px
    width 12.5%
    border-right 1px solid #000
    border-bottom 1px solid #000
</style>
